import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDiscord, faInstagram, faTwitter, faTiktok} from "@fortawesome/free-brands-svg-icons";
import kaBannerDesktopBg from "../assets/kinch-analytics-banner.jpg";

const socials = [
    {
        url: 'https://twitter.com/KinchAnalytics',
        icon: faTwitter,
    },
    {
        url: 'https://discord.com/invite/D9BJNWy3ej',
        icon: faDiscord,
    },
    {
        url: 'https://www.instagram.com/kinchanalytics/',
        icon: faInstagram,
    },
    {
        url: 'https://www.tiktok.com/@kinchanalytics/',
        icon: faTiktok,
    },
]

const AboutPage = () => (
    <div className="full-width pb-10 sm:pb-64">
        <header>
            <h1 className="xs:uppercase xs:mt-24 xs:mb-12 xs:text-center">About</h1>
        </header>
        <div className="full-width max-w-screen-md mx-auto">
            <p className="mb-6">Kinch Analytics is a professional Data Analytics company, currently specialising in
                Fortnite.</p>

            <p className="mb-6">Data Analytics is a huge part of every eSport, with millions of data points being
                produced in every game.
                We are here to provide insights from that data, giving value to all corners of the industry including
                teams/orgs, broadcasts and even directly to players!</p>

            <div className="w-full flex-shrink-0 gradient-to-b-wrapper rounded-3xl my-10 sm:my-16">
                <img src={kaBannerDesktopBg}
                     className="rounded-3xl p-px object-cover sm:h-64 w-full" alt="" />
            </div>

            <p className="mb-6">Kinch Analytics was founded by Daniel “Kinch” Sheppard, an Oxford Mathematics graduate
                and career Data Analyst with a passion for Fortnite.
                We have provided consultancy and data services to some of the biggest names in the scene, including BLAST.tv and Epic Games products.
                The company is now expanding to be able to provide more extensive and better services to the whole
                industry!</p>

            <p className="mb-6">Please contact kinchanalytics@gmail.com for any business enquiries.</p>

            <div className="w-full hidden sm:block">
                <p className="mb-6">You can also find us on our socials:</p>
                <div className="flex w-full justify-center text-4xl">
                    {socials.map((item, idx) =>
                        <div className="mr-1.5 2xs:mr-2.5 h-20 w-20 p-0.5 rounded-full bg-gradient-to-r from-ka-cyan via-ka-blue to-ka-purple"
                             key={idx}>
                            <a href={item.url} className="h-full w-full rounded-full flex items-center justify-center bg-gradient-to-l from-ka-cyan via-ka-blue to-ka-purple">
                                <FontAwesomeIcon className="text-white" icon={item.icon} />
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
);

export default AboutPage;